
import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { WidgetInstance } from "friendly-challenge";

@Component
export default class PuzzleCaptchaComponent extends Vue {
	@Ref("captcha") captcha!: HTMLElement;
	widget!: WidgetInstance;

	@Prop({ required: false }) value!: any;

	@Prop({ default: "none" }) mode!: "none" | "auto" | "focus";

	state: "pending" | "ongoing" | "done" | "errored" = "pending";

	mounted() {
		this.createWidget();
	}

	createWidget(mode: "none" | "auto" | "focus" = this.mode) {
		this.widget = new WidgetInstance(this.captcha, {
			puzzleEndpoint: process.env.VUE_APP_PUZZLE_ENDPOINT,
			startMode: mode,
			startedCallback: () => {
				this.state = "ongoing";
			},
			doneCallback: (solution: string) => {
				this.state = "done";
				this.$emit("input", solution);
				this.$emit("done", solution);
			},
			errorCallback: () => {
				this.state = "errored";
			},
		});
		//this.widget.start();
	}

	reset() {
		this.widget.reset();
		this.$emit("input", null);
	}

	safeReset() {
		if (!["ongoing"].includes(this.state)) {
			return this.reset();
		}
	}

	recreate(mode: "none" | "auto" | "focus" = this.mode) {
		this.createWidget(mode);
	}
}
