import LoadingComponent from "./Loading.vue";

export const LazyLoad = (component: any) => {
	const AsyncHandler = () => ({
		component,
		// A component to use while the component is loading.
		loading: LoadingComponent,
		// Delay before showing the loading component.
		// Default: 200 (milliseconds).
		delay: 400,
		// A fallback component in case the timeout is exceeded
		// when loading the component.
		// error: require('@views/_timeout.vue').default,
		// Time before giving up trying to load the component.
		// Default: Infinity (milliseconds).
		timeout: 10000,
	});

	return Promise.resolve({
		functional: true,
		render(h: any, { data, children }: any) {
			// Transparently pass any props or children
			// to the view component.
			return h(AsyncHandler, data, children);
		},
	});
};
