import axios, { AxiosResponse, AxiosRequestConfig, AxiosHeaders, AxiosResponseHeaders } from "axios";

export const ACTIONS = {
	CHECK_SESSION: process.env.VUE_APP_CHECK_SESSION_ACTION as string,
	LOAD_COUNTRIES: "/country/options",
	FETCH_SETUP_DETAILS: process.env.VUE_APP_PROFILE_SETUP_ACTION as string,
	SIGNUP_BEGIN: process.env.VUE_APP_SIGNUP_BEGIN_ACTION as string,
	FETCH_CHALLENGE: "/challenge/%s",
	FETCH_INVITATION_INFO: "/account/invitation/%s/info",
	FETCH_OAUTH_ACCOUNTS: "/oauth/dialog/%s/accounts",
};

export interface APIError {
	code: string;
	message: string;
	info?: Record<string, any>;
	fields?: any[];
}

export class APIResponse {
	constructor(public raw: AxiosResponse<any>) {}

	get statusCode() {
		return this.raw.status;
	}

	get body() {
		return this.raw.data;
	}

	get message() {
		return this.body?.message;
	}

	get data() {
		return this.body?.data;
	}

	get headers() {
		return this.raw.headers as AxiosResponseHeaders;
	}

	get ok() {
		return [200, 201].includes(this.statusCode);
	}

	get error() {
		return this.raw.data?.error as APIError;
	}

	get validationFailed() {
		if (this.error && this.error.code && this.error.code === "INPUT_VALIDATION_ERROR") {
			return true;
		}
		return false;
	}

	get validationErrors() {
		if (!this.validationFailed) {
			return {};
		}
		const fields: { parameter: string; message: string | string[] }[] = this.error.fields ?? [];
		return fields.reduce((errors: Record<string, string[]>, { parameter, message }) => {
			if (!errors[parameter]) {
				errors[parameter] = [];
			}
			const issues = Array.isArray(message) ? message : [message];
			errors[parameter].push(...issues);
			return errors;
		}, {});
	}
}

export class API {
	public http = axios.create({
		withCredentials: true,
		validateStatus: () => {
			return true;
		},
	});

	post(uri: string, data: any, config?: AxiosRequestConfig) {
		return this.http.post(uri, data, config).then((response) => new APIResponse(response));
	}

	delete(uri: string, config?: AxiosRequestConfig) {
		return this.http.delete(uri, config).then((response) => new APIResponse(response));
	}

	get(uri: string, config?: AxiosRequestConfig) {
		return this.http.get(uri, config).then((response) => new APIResponse(response));
	}
}

export const api = new API();
