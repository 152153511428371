import { api, ACTIONS } from "@/services/http";
import Vue from "vue";
import Vuex from "vuex";
import format from "format-util";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		signedIn: false,
		user: "",
		identity: "",
		defaultCountry: "",
		country: null,
		countries: [],
		countriesMap: {} as Record<string, string>,
		context: "",
		setupDetails: {} as Record<string, any>,
		signupState: {} as Record<string, any>,
		invitationState: {} as Record<string, any>,
		oauthAccountState: { accounts: [] } as { accounts: any[] },
	},
	mutations: {
		SET_CURRENT_USER(state, user) {
			state.user = user;
		},
		SET_CURRENT_LOGIN_IDENTITY(state, identity) {
			state.identity = identity;
		},
		SET_SIGNEDIN_STATUS(state, signedIn) {
			state.signedIn = signedIn;
		},
		SET_COUNTRIES(state, countries) {
			state.countries = countries;
		},
		SET_COUNTRIES_MAP(state, map) {
			state.countriesMap = map;
		},
		SET_CURRENT_COUNTRY(state, country) {
			state.country = country;
		},
		SET_DEFAULT_COUNTRY(state, defaultCountry) {
			state.defaultCountry = defaultCountry;
		},
		SET_SETUP_DETAILS(state, details) {
			state.setupDetails = details;
		},
		SET_SIGNUP_STATE(state, details) {
			state.signupState = details;
		},
		SET_INVITATION_STATE(state, details) {
			state.invitationState = details;
		},
		SET_OAUTH_ACCOUNTS_STATE(state, details) {
			state.oauthAccountState = details;
		},
	},
	actions: {
		async CHECK_SESSION({ commit }, payload) {
			const response = await api.get(ACTIONS.CHECK_SESSION, {
				params: { referrer: payload.referrer },
			});
			if (response.ok) {
				commit("SET_SIGNEDIN_STATUS", true);
				commit("SET_CURRENT_USER", response.data.user);
				commit("SET_CURRENT_COUNTRY", response.data.country);
				commit("SET_CURRENT_LOGIN_IDENTITY", response.data.identity);
				return {
					signedIn: true,
					forceReauth: response.data.forceReauth === "1",
					forceSetup: response.data.forceSetup === "1",
					state: response.data.state,
				};
			} else if (response.statusCode === 403) {
				return {
					signedIn: false,
					forceLogout: response.error?.info?.forceLogout === "yes",
					state: response.error?.info?.state,
				};
			}
			return { signedIn: false, forceLogout: false };
		},
		async LOAD_COUNTRIES({ commit, state }, payload) {
			if (
				state.countries.length &&
				state.defaultCountry &&
				(payload.suggested === undefined || String(payload.suggested).toUpperCase() === state.defaultCountry) &&
				((payload.context && payload.context === state.context) || !payload.context)
			) {
				return true;
			}
			const response = await api.get(ACTIONS.LOAD_COUNTRIES, {
				params: payload,
			});
			if (response.ok) {
				commit("SET_DEFAULT_COUNTRY", response.body.data.defaultCountry);
				commit("SET_COUNTRIES", Object.keys(response.body.data.countries));
				commit("SET_COUNTRIES_MAP", response.body.data.countries);
				console.log("fetched countries");
				return true;
			}
			return false;
		},
		async FETCH_SETUP_DETAILS({ commit }, payload) {
			const response = await api.get(ACTIONS.FETCH_SETUP_DETAILS, {
				params: payload,
			});
			if (response.ok) {
				commit("SET_SETUP_DETAILS", response.body.data);
				return true;
			}
			return false;
		},
		async BEGIN_SIGNUP({ commit }, payload) {
			const response = await api.get(ACTIONS.SIGNUP_BEGIN, { params: payload });
			if (response.ok) {
				commit("SET_SIGNUP_STATE", response.body.data);
				return true;
			}
			return false;
		},
		async FETCH_INVITATION_INFO({ commit }, { id, ...params }) {
			const response = await api.get(format(ACTIONS.FETCH_INVITATION_INFO, id), { params });
			if (response.ok) {
				commit("SET_INVITATION_STATE", response.body.data);
				return response;
			}
			return response;
		},
		async FETCH_OAUTH_ACCOUNTS({ commit }, { id, ...params }) {
			const response = await api.get(format(ACTIONS.FETCH_OAUTH_ACCOUNTS, id), { params });
			if (response.ok) {
				commit("SET_OAUTH_ACCOUNTS_STATE", response.body.data);
				return response;
			}
			return response;
		},
		async FETCH_CHALLENGE(_, payload) {},
	},
	modules: {},
});
