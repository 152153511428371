import { Directive, DirectiveBinding, VNode } from "vue";

interface StatefulBindingValue {
	html?: string;
	text?: string;
	busyClass?: string;
	indicator?: string;
	busy: boolean;
}

const Run = (el: HTMLElement, binding: DirectiveBinding<StatefulBindingValue>, vnode: VNode) => {
	const value = binding.value;
	const prevent = (e: Event) => e.preventDefault();
	const disable = () => {
		if (value.busyClass) {
			el.classList.add(value.busyClass);
		}
		if (el.tagName === "A") {
			el.addEventListener("click", prevent);
		} else if (el.tagName === "BUTTON") {
			(el as HTMLButtonElement).disabled = true;
		}
	};
	const enable = () => {
		if (el.tagName === "A") {
			if (value.busyClass) {
				el.classList.remove(value.busyClass);
			}
			el.removeEventListener("click", prevent);
		} else if (el.tagName === "BUTTON") {
			(el as HTMLButtonElement).disabled = false;
		}
	};
	if (binding.value.busy) {
		const html = value.html ?? value.text ?? "Submitting...";

		let indicator = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ';
		if (value.indicator !== undefined) {
			indicator = value.indicator;
		}
		el.innerHTML = `${indicator}${html}`;
		disable();
	} else {
		el.innerHTML = el.dataset.content as string;
		enable();
	}
};

export const StatefulDirective: Directive = {
	bind(el: HTMLElement, binding: DirectiveBinding<StatefulBindingValue>, vnode: VNode) {
		// Save the initial button content for later use

		el.dataset.content = el.innerHTML;
		Run(el, binding, vnode);
	},
	update(el: HTMLElement, binding: DirectiveBinding<StatefulBindingValue>, vnode: VNode) {
		Run(el, binding, vnode);
	},
};
