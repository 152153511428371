import { LazyLoad } from "@/components/LazyLoad";
import store from "@/store";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { composeUrl, isInternalUrl, redirect } from "./utils";
import { APIResponse } from "@/services/http";

const SYNTHETIC_PROP_SYMBOL = Symbol("SyntheticPropSymbol");

declare module "vue-router" {
	interface RouteMeta {
		[SYNTHETIC_PROP_SYMBOL]?: Record<string, any>;
	}
}

Vue.use(VueRouter);

enum RouteAccess {
	AUTH = "auth",
	NOAUTH = "no-auth",
	ANY = "any",
}

class SyntheticProp {
	static get(route: Route, key?: string) {
		const meta = route.meta;
		console.log("synthetic props ===>", (meta as any)[SYNTHETIC_PROP_SYMBOL]);
		const props = (route.meta ?? {})[SYNTHETIC_PROP_SYMBOL];
		if (!key || !props) {
			return props;
		}
		return props[key];
	}

	static set(route: Route, data: Record<string, any>) {
		if (!route.meta) {
			route.meta = {};
		}
		const props = route.meta[SYNTHETIC_PROP_SYMBOL];
		route.meta[SYNTHETIC_PROP_SYMBOL] = {
			...props,
			...data,
		};
	}

	static init(routes: RouteConfig[]) {
		const extended: RouteConfig[] = [];
		for (const route of routes) {
			if (!route.meta) {
				route.meta = {};
			}
			route.meta[SYNTHETIC_PROP_SYMBOL] = {};
			extended.push(route);
		}
		return extended;
	}
}

const routes: Array<RouteConfig> = [
	{
		path: "/",
		name: "home",
		redirect: { name: "profile" },
	},
	{
		path: "/about",
		name: "about",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => LazyLoad(import(/* webpackChunkName: "about" */ "../views/About.vue")),
	},
	{
		path: "/account/signup",
		name: "signup",
		component: () => LazyLoad(import(/* webpackChunkName: "signup" */ "../views/Register.vue")),
		meta: {
			// access: RouteAccess.NOAUTH,
			async beforeResolve(router: VueRouter, to: Route, from: Route, next: any) {
				const begin = await store.dispatch("BEGIN_SIGNUP", {
					// eslint-disable-next-line @typescript-eslint/camelcase
					client_id: to.query.client_id,
					// eslint-disable-next-line @typescript-eslint/camelcase
					rp_state: to.query.rp_state,
				});
				if (begin === false) {
					// setup error
					return next(false);
				}
				if (!store.state.signupState.session) {
					return next();
				}
				return next({ name: "reauth", query: { state: store.state.signupState.state } });
			},
		},
		props: (route) => ({
			query: route.query,
			defaultCountry: store.state.signupState.defaultCountry,
			countries: store.state.signupState.countries,
			state: store.state.signupState.state,
		}),
	},
	{
		path: "/account/password/forgot",
		name: "forgot",
		component: () => LazyLoad(import(/* webpackChunkName: "forgot-password" */ "../views/Forgot.vue")),
		meta: {
			access: RouteAccess.NOAUTH,
			async beforeResolve(router: VueRouter, to: Route, from: Route, next: any) {
				const setup = await Promise.all([
					store.dispatch("LOAD_COUNTRIES", {
						suggested: to.query.defaultCountry,
						context: to.query.context,
						mode: to.query.mode,
					}),
				]);
				if (setup.some((value) => value === false)) {
					// setup error
				}
				next();
			},
		},
		props: (route) => ({ query: route.query, defaultCountry: store.state.defaultCountry, countries: store.state.countriesMap }),
	},
	{
		path: "/account/mfa/pin/forgot",
		name: "forgot-pin",
		component: () => LazyLoad(import(/* webpackChunkName: "forgot-pin" */ "../views/ForgotPin.vue")),
		meta: {
			access: RouteAccess.NOAUTH,
			async beforeResolve(router: VueRouter, to: Route, from: Route, next: any) {
				const setup = await Promise.all([
					store.dispatch("LOAD_COUNTRIES", {
						suggested: to.query.defaultCountry,
						context: to.query.context,
						mode: to.query.mode,
					}),
				]);
				if (setup.some((value) => value === false)) {
					// setup error
				}
				next();
			},
		},
		props: (route) => ({ query: route.query, defaultCountry: store.state.defaultCountry, countries: store.state.countries }),
	},
	{
		path: "/account/signin",
		name: "signin",
		component: () => LazyLoad(import(/* webpackChunkName: "signin" */ "../views/Login.vue")),
		meta: {
			access: RouteAccess.NOAUTH,
			async beforeResolve(router: VueRouter, to: Route, from: Route, next: any) {
				const setup = await Promise.all([
					store.dispatch("LOAD_COUNTRIES", {
						suggested: to.query.defaultCountry,
						context: to.query.context,
						mode: to.query.mode,
					}),
				]);
				if (setup.some((value) => value === false)) {
					// setup error
				}
				next();
			},
		},
		props: (route) => ({ query: route.query, defaultCountry: store.state.defaultCountry, countries: store.state.countries }),
	},
	{
		path: "/account/session/confirm",
		name: "reauth",
		component: () => LazyLoad(import(/* webpackChunkName: "reauth" */ "../views/ConfirmSession.vue")),
		meta: {
			access: RouteAccess.AUTH,
		},
		props: (route) => ({ query: route.query }),
	},
	{
		path: "/account/setup",
		name: "setup",
		component: () => LazyLoad(import(/* webpackChunkName: "setup" */ "../views/SetupProfile.vue")),
		meta: {
			access: RouteAccess.AUTH,
			async beforeResolve(router: VueRouter, to: Route, from: Route, next: any) {
				const setup = await store.dispatch("FETCH_SETUP_DETAILS", {
					state: to.query.state,
				});
				if (setup === false) {
					// setup error
					return next(false);
				}
				if (store.state.setupDetails?.setup === true) {
					if (store.state.setupDetails?.next) {
						if (isInternalUrl(router, store.state.setupDetails?.next)) {
							return next(store.state.setupDetails?.next);
						}
						next(false);
						return redirect(router, store.state.setupDetails?.next, false);
					}
					return next({ name: "profile" });
				}
				next();
			},
		},
		props: (route) => ({
			query: route.query,
			info: store.state.setupDetails.info,
			countries: store.state.setupDetails.countries,
		}),
	},
	{
		path: "/account/profile",
		alias: "/account",
		name: "profile",
		component: () => LazyLoad(import(/* webpackChunkName: "profile" */ "../views/Profile.vue")),
		meta: {
			access: RouteAccess.AUTH,
		},
	},
	{
		path: "/account/oauth/interaction/:id/select-account",
		name: "select-account",
		component: () => LazyLoad(import(/* webpackChunkName: "select-account" */ "../views/SelectAccount.vue")),
		meta: {
			access: RouteAccess.AUTH,
		},
		props: (route) => ({
			query: route.query,
			uid: route.params.id,
		}),
	},
	{
		path: "/invitation/:id",
		name: "invitation",
		component: () => LazyLoad(import(/* webpackChunkName: "invitation" */ "../views/Invitation.vue")),
		meta: {
			// access: RouteAccess.AUTH,
			async beforeResolve(router: VueRouter, to: Route, from: Route, next: any) {
				const response: APIResponse = await store.dispatch("FETCH_INVITATION_INFO", {
					id: to.params.id,
					// eslint-disable-next-line @typescript-eslint/camelcase
					client_id: to.query.client_id,
				});
				if (response.ok) {
					SyntheticProp.set(to, { info: response.data });
					return next();
				}
				SyntheticProp.set(to, { error: response.error });
				return next();
			},
		},
		props: (route) => ({
			...SyntheticProp.get(route),
			query: route.query,
		}),
	},
	{
		path: "*",
		name: "404",
		component: () => LazyLoad(import(/* webpackChunkName: "generic-error" */ "../views/errors/GenericError.vue")),
		meta: {
			access: RouteAccess.ANY,
		},
		props: { title: "404 Page Not Found!", description: "Oops. The page you were looking for does not exist or may have been moved." },
	},
];

const router = new VueRouter({
	mode: "history",
	base: "/",
	routes: SyntheticProp.init(routes),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

router.beforeEach((to, _from, next) => {
	// If this isn't an initial page load.
	if (to.name) {
		// Start the route progress bar.
		NProgress.start();
	}
	// Check if auth is required on this route
	// (including nested routes).
	const access = to.meta?.access;

	return store
		.dispatch("CHECK_SESSION", { referrer: to.fullPath })
		.then(({ signedIn, forceLogout, state, forceSetup, forceReauth }) => {
			// If auth isn't required for the route, just continue.
			if (!access || access === RouteAccess.ANY) {
				return next();
			}
			// Then continue if the token still represents a valid user,
			if (access === RouteAccess.AUTH) {
				// otherwise redirect to login.
				if (signedIn) {
					if (forceReauth && to.name !== "reauth") {
						return next({ name: "reauth", query: { state } });
					}
					if (forceSetup && to.name !== "setup") {
						return next({ name: "setup", query: { state } });
					}
					return next();
				}
				if (forceLogout) {
					window.location.replace(composeUrl(process.env.VUE_APP_SIGNOUT_URL as string, { state }));
					return next(false);
				}
				return next({ name: "signin", query: { continue: to.fullPath } });
			}
			if (signedIn) {
				// Check for oauth context
				if (to.name && to.name === "signin" && to.query.mode === "oauth" && to.query.context) {
					return next();
				}
				// Already logged in ad we want users with no session
				return next({ name: "profile" });
			}
			return next();
		})
		.catch((error) => {
			next(error);
		});
});

router.beforeResolve(async (to, from, next) => {
	// Create a `beforeResolve` hook, which fires whenever
	// `beforeRouteEnter` and `beforeRouteUpdate` would. This
	// allows us to ensure data is fetched even when params change,
	// but the resolved route does not. We put it in `meta` to
	// indicate that it's a hook we created, rather than part of
	// Vue Router (yet?).
	try {
		// For each matched route...
		for (const route of to.matched) {
			await new Promise<void>((resolve, reject) => {
				// If a `beforeResolve` hook is defined, call it with
				// the same arguments as the `beforeEnter` hook.
				if (route.meta && route.meta.beforeResolve) {
					route.meta.beforeResolve(router, to, from, (...args: any[]) => {
						// If the user chose to redirect...
						if (args.length) {
							// If redirecting to the same route we're coming from...
							if (from.name === args[0].name) {
								// Complete the animation of the route progress bar.
								NProgress.done();
							}
							// Complete the redirect.
							next(...args);
							reject(new Error("Redirected"));
						} else {
							resolve();
						}
					});
				} else {
					// Otherwise, continue resolving the route.
					resolve();
				}
			});
		}
		next();
		// If a `beforeResolve` hook chose to redirect, just return.
	} catch (error) {
		return;
	}
});

router.afterEach(() => {
	// Complete the animation of the route progress bar.
	NProgress.done();
});

export default router;
