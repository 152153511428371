
import { Component, Prop, Vue } from "vue-property-decorator";
import CountryFlag from "vue-country-flag";

@Component({
	components: {
		CountryFlag,
	},
})
export default class Header extends Vue {
	@Prop() private msg!: string;

	public popover = false;
	public popoverShowClass = "show";
	public signoutUrl = process.env.VUE_APP_SIGNOUT_URL;

	public togglePopover() {
		this.popover = !this.popover;
	}

	public closePopover($event: any) {
		this.popover = false;
	}

	get signedIn() {
		return this.$store.state.signedIn;
	}

	get username() {
		return this.$store.state.user;
	}

	get country() {
		return this.$store.state.country;
	}
}
