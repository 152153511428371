import VueRouter from "vue-router";

export const isInternalUrl = (router: VueRouter, url: string) => {
	const route = router.resolve(url);
	return route.resolved.matched.length && route.resolved.name !== "404";
};

export const redirect = (router: VueRouter, location: string, useRouter: boolean) => {
	const url = new URL(location, window.location.origin).href;
	console.log("Redirecting to ...", url, useRouter);
	if (useRouter && isInternalUrl(router, url)) {
		return router.replace(url);
	}
	return window.location.replace(url);
};

export const composeUrl = (base: string, query: Record<string, any> = {}) => {
	const [path, search = ""] = base.split("?");
	const params = new URLSearchParams(search);
	for (const [key, value] of Object.entries(query)) {
		if (value !== undefined) {
			params.append(key, value);
		}
	}
	return `${path}?${params.toString()}`;
};
