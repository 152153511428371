import Vue, { DirectiveBinding, VNode } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Loading from "./components/Loading.vue";
import Alert from "./components/Alert.vue";
import PuzzleCaptcha from "./components/PuzzleCaptcha.vue";
import FeatherIcon from "./components/FeatherIcon.vue";
import ThreeDotLoader from "./components/ThreeDotLoader.vue";
import GenericError from "./views/errors/GenericError.vue";
import { StatefulDirective } from "./directives/Stateful";
import { API } from "./services/http";
import VueSelect from "vue-select";
import VueClipboard from "vue-clipboard2";
import PortalVue from "portal-vue";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";

declare global {
	export const NProgress: any;
}

declare module "vue/types/vue" {
	interface Vue {
		$api: API;
		$isInternalUrl: (url: string) => boolean;
		$redirect: (url: string, useRouter?: boolean) => void;
		$url: (base: string, query?: Record<string, any>) => string;
	}
}

Vue.component("Alert", Alert);
Vue.component("PuzzleCaptcha", PuzzleCaptcha);
Vue.component("FeatherIcon", FeatherIcon);
Vue.component("ThreeDotLoader", ThreeDotLoader);
Vue.component("VueSelect", VueSelect);
Vue.component("GenericError", GenericError);
Vue.use(VueClipboard);
Vue.use(PortalVue);

Vue.directive("stateful", StatefulDirective);

Vue.prototype.$api = new API();

Vue.mixin({
	methods: {
		$isInternalUrl: function (url: string) {
			const route = this.$router.resolve(url);
			return route.resolved.matched.length && route.resolved.name !== "404";
		},
		$redirect: function (location: string, useRouter) {
			const url = new URL(location, window.location.origin).href;
			if (useRouter && this.$isInternalUrl(url)) {
				return this.$router.replace(url);
			}
			return window.location.replace(url);
		},
		$url: function (base: string, query: Record<string, any> = {}) {
			const [path, search = ""] = base.split("?");
			const params = new URLSearchParams(search);
			for (const [key, value] of Object.entries(query)) {
				if (value !== undefined) {
					params.append(key, value);
				}
			}
			const url = `${path}?${params.toString()}`;

			return url;
		},
	},
});

Vue.config.productionTip = false;

Vue.component("Loading", Loading);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
