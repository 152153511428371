
import { Component, Vue } from "vue-property-decorator";
import Header from "./components/layouts/Header.vue";
import Footer from "./components/layouts/Footer.vue";
import Content from "./components/layouts/Content.vue";
import "./App.scss";

@Component({
	components: {
		Header,
		Content,
		Footer,
	},
})
export default class App extends Vue {}
