
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class GenericError extends Vue {
	@Prop({ required: true })
	public title!: string;

	@Prop({ required: true })
	public description!: string;

	@Prop()
	public image!: string;
}
